import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: 120px 0 32px;

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 200px 0 40px;
  }

  h2 {
    margin-top: 24px;
  }

  .lead {
    & + .lead {
      margin-top: 32px;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        margin-top: 64px;
      }

      @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
        margin-top: 88px;
      }
    }
  }

  .legal-wrapper {
    margin-top: 24px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-top: 40px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      margin-top: 64px;
    }
  }

  form {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-top: 88px;
    }
  }

  button {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin: 40px 0 15px;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      margin-top: 80px;
    }
  }
`

export { Section }
