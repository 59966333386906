import React from 'react'
import Layout from '../layout/Layout'
import SectionHeaderContactForm from '../components/SectionHeaderContactForm'

export default function Contact() {
  return (
    <Layout
      metaTitle="Kontakt"
      metaDescription="Zapraszamy do kontaktu – odpowiemy na wszystkie pytania i przygotujemy ofertę zgodnie z Państwa oczekiwaniami"
      slug="/kontakt"
      before="Kontakt"
      hideForm
      reverseHeaderColors
    >
      <SectionHeaderContactForm
        title="Kontakt"
        lead={
          <>
            Zapraszamy do kontaktu – odpowiemy na wszystkie pytania
            i&nbsp;przygotujemy ofertę zgodnie z&nbsp;Państwa oczekiwaniami.
          </>
        }
        formTitle={
          <>
            Napisz <br />
            do nas
          </>
        }
      />
    </Layout>
  )
}
