import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Column, Row } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import InputWrapper from '../InputWrapper'
import Button from '../Button'
import { emailRegExp, phoneRegExp } from '../../helpers'
import { global } from '../../config/global'
import * as Styled from './SectionHeaderContactForm.styles'

const SectionHeaderContactForm = ({ title, smallTitle, lead, formTitle }) => {
  const [sent, setSent] = useState(false)

  const handleSubmit = (values, setSubmitting, resetForm) => {
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('phone', values.phone)
    formData.append('subject', values.subject)
    formData.append('email', values.email)
    formData.append('message', values.message)

    fetch('/api/contact.php', {
      method: 'POST',
      body: formData,
    })
      .then(() => {
        setSent(true)
        setSubmitting(false)
        resetForm({})
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  return (
    <Styled.Section>
      <Row alignBottom alignJustify>
        <Column xs={12} lg={4}>
          <DoubleTitle
            smallTitle={smallTitle}
            title={title}
            variant="h1"
            border="addon"
          />
          <p className="lead">{lead}</p>
          <p className="lead">
            Nordic Logistics <br />
            Polska Sp. z o.o.
          </p>
          <p>
            Siedziba główna: <br />
            BYDGOSZCZ <br />
            {global.address1} <br />
            {global.address2}
          </p>
          <p>
            Oddział: <br />
            TORUŃ <br />
            ul. Sportowa 18-20
            <br />
            87-100 Toruń
          </p>
          <p>
            T: <a href={`tel:${global.phone}`}>{global.phone}</a>
            <br />
            E: <a href={`mailto:${global.email}`}>{global.email}</a>
          </p>
          <p>
            NIP: 6832088830
            <br />
            REGON: 363846371
            <br />
            KRS: 0000604801
          </p>
        </Column>
        <Column xs={12} lg={7}>
          <DoubleTitle
            title={formTitle}
            variant="h3"
            border="addon"
            noBorderLarge
          />
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              subject: '',
              message: '',
              term: false,
            }}
            enableReinitialize
            validationSchema={Yup.object({
              name: Yup.string().required('Pole wymagane.'),
              email: Yup.string()
                .matches(emailRegExp, 'Niewłaściwy format.')
                .required('Pole wymagane.'),
              phone: Yup.string()
                .matches(phoneRegExp, 'Niewłaściwy format.')
                .required('Pole wymagane.'),
              subject: Yup.string().required('Pole wymagane.'),
              message: Yup.string().required('Pole wymagane.'),
              term: Yup.bool().oneOf([true], 'Pole wymagane.'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) =>
              handleSubmit(values, setSubmitting, resetForm)
            }
          >
            {({ values, errors, touched }) => (
              <Form noValidate>
                <InputWrapper
                  name="name"
                  type="text"
                  label="Imię i nazwisko"
                  error={errors.name}
                  touched={touched.name}
                  inputValue={values.phone}
                />
                <Row>
                  <Column xs={12} xl={6}>
                    <InputWrapper
                      name="email"
                      type="text"
                      label="E-mail"
                      error={errors.email}
                      touched={touched.email}
                      inputValue={values.email}
                    />
                  </Column>
                  <Column xs={12} xl={6}>
                    <InputWrapper
                      name="phone"
                      type="text"
                      label="Tel"
                      error={errors.phone}
                      touched={touched.phone}
                      inputValue={values.phone}
                    />
                  </Column>
                </Row>
                <InputWrapper
                  name="subject"
                  type="text"
                  label="Temat"
                  error={errors.subject}
                  touched={touched.subject}
                  inputValue={values.subject}
                />
                <InputWrapper
                  name="message"
                  label="Treść..."
                  component="textarea"
                  col={6}
                  error={errors.message}
                  touched={touched.message}
                  inputValue={values.message}
                />
                <Row alignBottom alignJustify>
                  <Column xs={12} lg={7} xl={8}>
                    <InputWrapper
                      name="term"
                      type="checkbox"
                      className="checkbox-wrapper centered"
                      label="Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z formularzem kontaktowym. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany, że przysługuje mi prawo do dostępu swoich danych, możliwości ich poprawienia, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest Nordic Logistics Polska Sp. z o. o., z siedzibą w Bydgoszczy przy ul. Kasztanowej 57, 85-605 Bydgoszcz."
                      error={errors.term}
                      touched={touched.term}
                      inputValue={values.term}
                    />
                  </Column>
                  <Column xs={12} lg={5} xl={4} textRight>
                    <Button primary type="submit">
                      Wyślij
                    </Button>
                  </Column>
                  {sent && (
                    <Column xs={12}>
                      <p className="lead">Wiadomość wysłana. Dziękujemy.</p>
                    </Column>
                  )}
                </Row>
              </Form>
            )}
          </Formik>
        </Column>
      </Row>
    </Styled.Section>
  )
}

export default SectionHeaderContactForm
